import { Header, TypeFormButton } from 'components';

import { GetStartedButton } from './GetStartedButton';

export function HeroSection() {
  return (
    <div className="w-auto md:w-[720px] mx-auto h-full relative mt-24 md:mt-[30px] px-6 xxs:px-10">
      <div className="flex flex-col xl:w-full w-full relative z-[1]">
        <div className="flex flex-col w-full">
          <Header
            tag={'h1'}
            classNames={{
              header:
                'min-w-[340px] text-[48px] xxs:text-[54px] md:text-[90px] 2xl:text-[118px] leading-[20px] md:leading-[52px] 2xl:leading-[62px] font-["Arcade"] pixelport-text-shadow capitalize whitespace-nowrap	',
            }}
          >
            LIQUID NFTs
          </Header>
          <Header
            tag={'h1'}
            classNames={{
              header:
                'min-w-[340px] text-[40px] md:text-[48px]  2xl:text-[64px] leading-[64px] md:leading-[62px] 2xl:leading-[72px] font-["Arcade"] pixelport-text-shadow capitalize',
            }}
          >
            SIMPLIFIED
          </Header>
          <Header
            tag={'p'}
            classNames={{
              header:
                'text-[16px] leading-[21px] md:!text-[20px] 2xl:!text-[22px] normal-case text-lightBlack pt-[16px] pr-[40px] md:pr-[180px] lg:pr-[0px] font-Jakarta text-justify font-semibold md:mt-6',
            }}
          >
            Mint, Fractionalize, Transfer your NFT on any chain
          </Header>
          <Header
            tag={'p'}
            classNames={{
              header:
                'text-base leading-5 md:!text-lg 2xl:!text-lg normal-case text-lightBlack pt-[17px] md:pixelport-text-shadow pr-[40px] md:pr-[180px] lg:pr-[0px] font-Jakarta text-justify',
            }}
          >
            Effortless cross-chain minting, fractionalization, and transfer of NFTs. At Pixelport,
            we turn digital assets into vibrant playgrounds of imagination. Our mission? To make NFT
            creation and trading as fun, accessible, and secure as possible.
          </Header>
        </div>
        <div className="flex flex-col lg:flex-row gap-0 lg:gap-10 md:gap-6 mt-4 lg:mt-[22px]">
          <div className="flex xl:items-center md:items-start gap-3 xl:gap-8 xl:flex-row md:flex-col sm:flex-row">
            <TypeFormButton formId="uvT7QzSD" as="div">
              <GetStartedButton
                title="get early access"
                arrowStroke="black"
                classNames={{
                  base: '!bg-black !text-white',
                  container: 'bg-white',
                  text: 'font-Jakarta font-semibold',
                }}
              />
            </TypeFormButton>
          </div>
          <div className="flex flex-row gap-4 md:gap-10 mt-10 md:mt-0">
            <img src="./analogLogo.svg" alt="analog" className="md:w-[153px] w-[120px]" />
            <img src="./encrypt.svg" alt="encrypt" className="md:w-[165px] w-[120px]" />
          </div>
        </div>
      </div>
    </div>
  );
}
